<template>
  <div>
    
    <NavBar style="background-color: #fff" :iconColor="'#9B9B9B'">
      <div slot="title" class="title" @click="showTxMap()">
        <van-icon name="location-o" color="#9B9B9B" />
        <div class="location-box">
          <marquee v-if="location.addr">
            <span>
              {{ location.addr }}
            </span>
          </marquee>
          <span v-else>请选择地址</span>
        </div>
        <van-icon name="arrow" color="#9B9B9B" />
      </div>
    </NavBar>
    
    
    <van-search
      v-model="searchValue"
      background="#fff"
      shape="round"
      placeholder="请输入搜索关键词"
      @search="selectShop()"
    />
    <van-dropdown-menu>
      <van-dropdown-item :title="menuTitle" ref="dropdown">
        <van-tree-select
          :items="listClass"
          :main-active-index.sync="activeIndex"
          :active-id.sync="activeId"
          @click-item="getClass"
          @click-nav="clickNav"
        />
        <!-- <van-tree-select
          :items="items"
          :active-id.sync="activeId"
          :main-active-index.sync="activeIndex"
        /> -->
      </van-dropdown-item>
      <van-dropdown-item v-model="value" :options="option2" />
    </van-dropdown-menu>
    <LiveGoodsList
      ref="shopList"
      :shopClassId="shopClassId"
      :shopName="searchValue"
      :byType="value"
      @selectPosition="showTxMap"
      @showBlank="showBlank"
    ></LiveGoodsList>
    <TxMap ref="txMap" @setLocation="setLocation"></TxMap>
    <MapSearch
      :lat="lat"
      :lon="lon"
      v-if="showMap"
      @setDetailAddress="setDetailAddress"
      @endSearch="endSearch"
      class="map-search"
    ></MapSearch>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import LiveGoodsList from "@/components/shop-list/live-goods-list";
import { getShopClass } from "@/api/shop.js";
import TxMap from "@/components/map/tx-map";
import MapSearch from "@/components/map/mapSearch";
import { Toast } from "vant";
import Navigation from "@/components/map/navigation";
export default {
  name: "native-live",
  components: {
    NavBar,
    LiveGoodsList,
    TxMap,
    MapSearch,
  },
  data() {
    return {
      activeIndex: 0,
      activeId: 0,
      showMap: false,
      searchValue: "",
      menuTitle: "全部", // 下拉分类菜单标题
      shopClassId: 0,
      lon: 106.55034,
      lat: 29.56208,
      listClass: [],
      value: 1, // 排序匹配标识
      transitClassId: 0, // 分类id
      option2: [
        { text: "智能排序", value: 1 },
        { text: "距离优先", value: 2 },
        { text: "评分优先", value: 3 },
      ],
      location: {
        addr: "",
      },
    };
  },
  watch: {
    value: function () {
      let _that = this;
      console.log(
        "监听this.transitClassId===============",
        this.transitClassId
      );
      this.$refs.shopList.startShopList(this.transitClassId);
      // setTimeout(function () {
      //   _that.$refs.shopList.startShopList(this.transitClassId);
      // }, 5);
    },
  },
  mounted() {
    this.getShopClass();
    // this.$refs.txMap.getCityLocation();
  },
  methods: {
    endSearch() {
      this.showMap = false;
    },
    showTxMap() {
      this.showMap = true;
    },
    showBlank(bool) {
      if (bool) {
        // this.location.addr = "";
      }
    },
    setLocation(location) {
      this.location = location;
      // console.log(location);
      this.lat = location.lat;
      this.lon = location.lng;
      // console.log(this.lat, this.lon);
      this.$refs.shopList.setLocation(this.lat, this.lon);
      this.$refs.shopList.startShopList();
    },
    setDetailAddress(location) {
      this.location.addr = location.poiname;
      this.lon = location.latlng.lng;
      this.lat = location.latlng.lat;
      this.showMap = false;
      this.$refs.shopList.setLocation(this.lat, this.lon);
      this.$refs.shopList.startShopList();
    },
    selectShop() {
      this.$refs.shopList.startShopList();
    },
    clickNav(index) {
      if (index === 0) {
        this.$refs.shopList.startShopList(0);
        this.activeId = 0;
        this.menuTitle = "全部";
        this.$refs.dropdown.toggle(false);
      }
    },
    getClass(data) {
      // data拿到的是选中的分类数据
      console.log("------------右分类触发-------------");
      this.menuTitle = data.text;
      this.shopClassId = data.id;
      this.$refs.dropdown.toggle(false);
      if (data.id) {
        console.log("data.id==========", data.id);
        this.transitClassId = data.id;
      }
      this.$refs.shopList.startShopList(data.id);
    },
    async getShopClass() {
      const res = await getShopClass();
      if (res.code * 1 === 1) {
        res.data.unshift({
          id: 0,
          text: "全部",
        });
        this.listClass = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
}
// 搜索框样式
.van-search {
  padding-top: 58px;
}
.van-search__content {
  background-color: #fff;
}
.van-search__content--round {
  border: 2px solid #fa0336;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
}
/deep/.van-ellipsis {
  font-weight: 500;
  font-size: 18px;
}
.map-search {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
}
.location-box {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 13px;
  color: #656565;
}
/deep/.van-sidebar-item__text {
  font-size: 17px;
}
/deep/.van-tree-select__item {
  font-size: 17px !important;
}
/deep/.van-cell__title {
  font-size: 16px;
}
/deep/.van-dropdown-menu__item {
  flex: none;
  &:last-child {
    margin-left: 30px;
  }
  &:first-child {
    margin-left: 12px;
  }
}
</style>
